import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_OpenHAB_Tutorials/PrimaryBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "OpenHAB v3 Rules for your WQHD INSTAR MQTTv5 Broker",
  "path": "/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_III/",
  "dateChanged": "2022-08-17",
  "author": "Mike Polinowski",
  "excerpt": "I want to use my IN-9408 2k+ with the new Version 3 of OpenHAB. The camera MQTT Broker supports the new MQTTv5. How can use this interface to control my camera?",
  "image": "../FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "WQHD & UHD Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='OpenHAB v3 Rules for your WQHD INSTAR MQTTv5 Broker' dateChanged='2022-08-17' author='Mike Polinowski' tag='INSTAR IP Camera' description='I want to use my IN-9408 2k+ with the new Version 7 of OpenHAB. The camera MQTT Broker supports the new MQTTv5. How can use this interface to control my camera?' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_III/' locationFR='/fr/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_III/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "openhab-v3-rules-for-your-wqhd-instar-mqttv5-broker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#openhab-v3-rules-for-your-wqhd-instar-mqttv5-broker",
        "aria-label": "openhab v3 rules for your wqhd instar mqttv5 broker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHAB v3 Rules for your WQHD INSTAR MQTTv5 Broker`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_I/"
        }}>{`Part I - MQTT Binding Configuration through the Main UI`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_II/"
        }}>{`Part II - MQTT Binding Configuration through the Configuration Files`}</a></li>
      <li parentName="ul">{`Part III - MQTT Binding Rules`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_IV/"
        }}>{`Part IV - MQTT Binding Scripts`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Frequently_Asked_Question/OpenHab3_INSTAR_WQHD_MQTTv5_Camera_Control_Part_V/"
        }}>{`Part V - Groups and Sitemaps`}</a></li>
    </ul>
    <h2 {...{
      "id": "mqtt-binding-rules--scripts",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mqtt-binding-rules--scripts",
        "aria-label": "mqtt binding rules  scripts permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MQTT Binding Rules & Scripts`}</h2>
    <p><strong parentName="p">{`A`}</strong>{`: Part 1 of this tutorial installed the OpenHAB MQTT binding and connected it to the internal broker in our INSTAR WQHD camera using the OpenHab 3 `}<strong parentName="p">{`Main UI`}</strong>{`. The `}<strong parentName="p">{`Part II`}</strong>{` did the same by editing the configuration files of our OpenHab 3 installation. Now it is time to add some automations using the OpenHab `}<strong parentName="p">{`Rules`}</strong>{` feature.`}</p>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#mqtt-binding-rules--scripts"
        }}>{`MQTT Binding Rules & Scripts`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#rules"
            }}>{`Rules`}</a></li>
        </ul>
      </li>
    </ul>
    {/* /TOC */}
    <h3 {...{
      "id": "rules",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#rules",
        "aria-label": "rules permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Rules`}</h3>
    <p><a parentName="p" {...{
        "href": "https://www.openhab.org/docs/configuration/rules-dsl.html"
      }}>{`Rules`}</a>{` are used for automating processes: Each rule can be triggered, which invokes a script that performs any kinds of tasks, e.g. turn on lights by modifying your items, do mathematical calculations, start timers etcetera.`}</p>
    <p>{`In camera surveillance time-of-day is often an important trigger to pan your camera to a different position, change the sensitivity of the motion detection, etc. Here we can use `}<strong parentName="p">{`CRON`}</strong>{` trigger that fire at a specific time of the day:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/df5c5ba572bce8720a736f50a6ecfcda/e751c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "46.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABZ0lEQVQoz22Qy27VMBRFM69QnZvEdnzs4+PEjyROGrcSNAxQ+w2IAQz4GSZIiI9GuWUC7dIe7Nl+VIgYY/Tep5RQ62lwzjkiN5C7WmctpZT2vRCR/cuLwco5V0rZ972UwjkXUvZSgtakAZXqlQIApVTXdfyKEEIpJaUUQlQhREdOCNH3vRCSMdY0TYzJWlIKlIJx9PM8E511rCWtDQAgkrVUHcfx9Py0LHMIwRjDGGvbdp6XcRiNNtqgc4MjZwwiWkRrDBqD1hKirVKatm0bvfc+aK3r+kwOMQkhbm/Z5XJp3gK07vu+SjHMUxoc+XHQoFhdd03zkJe93K/rOo7jdZD6TwBayr7Crz/991/88w/57Tf/+KV+d9PybtqmvGQi0tcEeIVSIKWsYjnW958oPwzbB3CBMdY1zRqTQYvnthPOefeKtm2rbZnK3ZqnGJw9a7O669q7+/z4eGzblvOa8/pyz78iAPgDC19C4dU5f4oAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/df5c5ba572bce8720a736f50a6ecfcda/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.avif 230w", "/en/static/df5c5ba572bce8720a736f50a6ecfcda/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.avif 460w", "/en/static/df5c5ba572bce8720a736f50a6ecfcda/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.avif 920w", "/en/static/df5c5ba572bce8720a736f50a6ecfcda/0eb28/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.avif 1123w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/df5c5ba572bce8720a736f50a6ecfcda/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.webp 230w", "/en/static/df5c5ba572bce8720a736f50a6ecfcda/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.webp 460w", "/en/static/df5c5ba572bce8720a736f50a6ecfcda/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.webp 920w", "/en/static/df5c5ba572bce8720a736f50a6ecfcda/33ebe/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.webp 1123w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/df5c5ba572bce8720a736f50a6ecfcda/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png 230w", "/en/static/df5c5ba572bce8720a736f50a6ecfcda/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png 460w", "/en/static/df5c5ba572bce8720a736f50a6ecfcda/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png 920w", "/en/static/df5c5ba572bce8720a736f50a6ecfcda/e751c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png 1123w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/df5c5ba572bce8720a736f50a6ecfcda/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_02.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "yml"
    }}><pre parentName="div" {...{
        "className": "language-yml"
      }}><code parentName="pre" {...{
          "className": "language-yml"
        }}><span parentName="code" {...{
            "className": "token key atrule"
          }}>{`configuration`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`triggers`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"2"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`configuration`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`time`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token datetime number"
          }}>{`18:00`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` timer.TimeOfDayTrigger
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`conditions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
`}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`actions`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`-`}</span>{` `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span>{`
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`configuration`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`itemName`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` PrivacyAreas
      `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`command`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` OFF
    `}<span parentName="code" {...{
            "className": "token key atrule"
          }}>{`type`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` core.ItemCommandAction`}</code></pre></div>
    <p>{`A more elegant option is the `}<a parentName="p" {...{
        "href": "https://www.openhab.org/addons/bindings/astro"
      }}>{`Astro Binding`}</a>{` that allows us to use the sunrise or sunset as triggers, as it is often the changing light condition that require the change in camera configuration. To use this function go to `}<strong parentName="p">{`Settings`}</strong>{`/`}<strong parentName="p">{`Bindings`}</strong>{` and install `}<em parentName="p">{`Astro`}</em>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/73faa2c90bfa196ffa25ab5357283152/b94e3/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "36.95652173913043%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABOElEQVQY02WPvU7DMBSFsyEV8mO7sQPKjf+SuHXTKKF0BSSqjpW6l5WFRwEJVSxlRELMmfsEfaIyBpUKAeUMZ7n69N3jCCHrui7LcjS6iCilBPu+X1XVfD6fzWZ9a6XSxvTyPA/DECGEf8UBiLMsU0pppcIwxBh7nrder9uvvKxWGk7H55W1NmLsEC7KajCsEiGBy7MY9ufNZrPdfrRt+/r2jgeXLKvc4w7+F2d491zfr/jigS2W7OoW+67r+U3T7M2PT8vu8Dq2Y4ICQsghnJmeLUqVZjo3cZJgvNtsrZ1Op5PJjVYKnRwFbicIUBAEPxwhmBDHGGP7fa1UmmqAeP92FEVSSi5Enuc9O+BCcs4hjimlf8zGmKIotNZpmgLsNiOEKKVSSiEE5wkAJMmuAYAx9m3uYhJ+An13S6KHr+EzAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/73faa2c90bfa196ffa25ab5357283152/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 230w", "/en/static/73faa2c90bfa196ffa25ab5357283152/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 460w", "/en/static/73faa2c90bfa196ffa25ab5357283152/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 920w", "/en/static/73faa2c90bfa196ffa25ab5357283152/bedb6/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.avif 1126w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/73faa2c90bfa196ffa25ab5357283152/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 230w", "/en/static/73faa2c90bfa196ffa25ab5357283152/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 460w", "/en/static/73faa2c90bfa196ffa25ab5357283152/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 920w", "/en/static/73faa2c90bfa196ffa25ab5357283152/65db1/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.webp 1126w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/73faa2c90bfa196ffa25ab5357283152/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 230w", "/en/static/73faa2c90bfa196ffa25ab5357283152/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 460w", "/en/static/73faa2c90bfa196ffa25ab5357283152/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 920w", "/en/static/73faa2c90bfa196ffa25ab5357283152/b94e3/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png 1126w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/73faa2c90bfa196ffa25ab5357283152/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_01.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We now need to create a `}<strong parentName="p">{`Thing`}</strong>{` from the `}<strong parentName="p">{`Sun Data`}</strong>{` we are now receiving from the Astro Binding:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3a8a5d2d9d9d535780edaf42e9e40416/78415/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.69565217391305%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABjElEQVQoz02Rv47UMBCHt0NC7K0dO7bjmfHkEtvZ/NlL7iqgoaRBIHQFL0BDi0QBz8I78Ipok9zpPk1l65N/8/Mh55xSzF0XwLcBEalmDiEQESLm3M3LcrncMTO9AFcOd/Pc98M4TrosT1IYY1LKITAiIVJK+fa2IQrMNVHYDgGwWDlERl8WYJQzWgjpnDv3fQhMFALzuR+GYWqalrkOgbchol1+9/V7/vDI77/484O4ORprc+6YGTwYYwHAe7DWOVc945zb5f7bH/j0s/r82z58lMfXujTjON7fL12XvfemLIUQ8gmxIqXc5UvXRLIpWHSllFIppbWWslBKlaVWShUvsNYys7N28w/TZW7aFFOHSEIIY+y571PKTdOklOu69h4AEACrynsPTKGunmJP05RzjjEi4lXed77Ww8x7c2tJzlXrOA68JTrMyzIMQ9u2m1waE5j9+pwH8JUzqtDF9QvtijEmxqS1vsrD0McVANg22ftQWr55Zd8+Tr/+dT/+FpTl6SjX29PpZov9H/DPRLLZslOIAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3a8a5d2d9d9d535780edaf42e9e40416/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.avif 230w", "/en/static/3a8a5d2d9d9d535780edaf42e9e40416/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.avif 460w", "/en/static/3a8a5d2d9d9d535780edaf42e9e40416/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.avif 920w", "/en/static/3a8a5d2d9d9d535780edaf42e9e40416/f4ff4/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.avif 1207w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3a8a5d2d9d9d535780edaf42e9e40416/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.webp 230w", "/en/static/3a8a5d2d9d9d535780edaf42e9e40416/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.webp 460w", "/en/static/3a8a5d2d9d9d535780edaf42e9e40416/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.webp 920w", "/en/static/3a8a5d2d9d9d535780edaf42e9e40416/459db/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.webp 1207w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3a8a5d2d9d9d535780edaf42e9e40416/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png 230w", "/en/static/3a8a5d2d9d9d535780edaf42e9e40416/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png 460w", "/en/static/3a8a5d2d9d9d535780edaf42e9e40416/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png 920w", "/en/static/3a8a5d2d9d9d535780edaf42e9e40416/78415/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png 1207w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3a8a5d2d9d9d535780edaf42e9e40416/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_03.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`This `}<strong parentName="p">{`Thing`}</strong>{` comes pre-configured with a `}<strong parentName="p">{`Channels`}</strong>{` for the Sunrise and Sunset that we can use:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/e083540eb89d0553b4b98e4c4dd58799/85053/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "59.56521739130435%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABlklEQVQoz32RW2udQBSF/QXRUccenT0X5+ZxNDheoqccQh7al1Io9KHQ59JC+v//QImTpEVCF4ths2HN+mBHQghrbdM0UinFQArOheCcM8b4rtu+W0YvpQzLfS+U4G3NIimV6/rWOaV1mmUY47IsCSGn06mqKgAAQmhVAlBKabWLEIKLIsc46htlBFQYwbsMZ2kcxwDgnOu67glnlzFW7DjGGGstpZAilCIUDV9+TV8f+cN3dv8NE5HEN1wI733bti8ZIaVkjDPG6/rpL0pZnudpmkby06P9/Lv6+BM+/MhBoviGENi2yzTNxtidloU3DACUMZ7nOUIo6kztNFf0pFlZ4AwhVFVknhfvx/O5ZYyH2MHPzW3Xua6XSittcFGE8N3dNo6Tc11dy9B28Eu4dX3fa62llEVRJEnCGF/X1ftRKR0gD+ZcPGN776dpsta+hgHoum7D4I2xbzL/xda71C6MccBe122eZ2stIfC/sPpHGOMkSTgX1+t1WZZh8E1z3s+jDg41x3Bovlzeb9ulac5v1r6e6g9zeFvIHe2nlQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e083540eb89d0553b4b98e4c4dd58799/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.avif 230w", "/en/static/e083540eb89d0553b4b98e4c4dd58799/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.avif 460w", "/en/static/e083540eb89d0553b4b98e4c4dd58799/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.avif 920w", "/en/static/e083540eb89d0553b4b98e4c4dd58799/3c69e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.avif 1225w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/e083540eb89d0553b4b98e4c4dd58799/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.webp 230w", "/en/static/e083540eb89d0553b4b98e4c4dd58799/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.webp 460w", "/en/static/e083540eb89d0553b4b98e4c4dd58799/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.webp 920w", "/en/static/e083540eb89d0553b4b98e4c4dd58799/1d10f/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.webp 1225w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/e083540eb89d0553b4b98e4c4dd58799/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png 230w", "/en/static/e083540eb89d0553b4b98e4c4dd58799/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png 460w", "/en/static/e083540eb89d0553b4b98e4c4dd58799/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png 920w", "/en/static/e083540eb89d0553b4b98e4c4dd58799/85053/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png 1225w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/e083540eb89d0553b4b98e4c4dd58799/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_04.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now, instead of using the UI, we can write down our rule - since we only have the Privacy Areas toggle, let's switch the privacy mode on once sunrise begins and turn it off again at the end of sunset:`}</p>
    <p><em parentName="p">{`rules/astro`}{`_`}{`scenes.rules`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}>{`rule `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Astro Privacy ON"`}</span>{`

when
      Channel `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'astro:sun:home:rise#event'`}</span>{` triggered `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`START`}</span>{` or Item  `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`TEST`}</span>{` received command `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ON`}</span>{`
then
      PrivacyAreas`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`sendCommand`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`ON`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      `}<span parentName="code" {...{
            "className": "token function"
          }}>{`logInfo`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Astro Privacy, astroScenes.rules"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Info message :: Privacy ON"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
end

rule `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Astro Privacy OFF"`}</span>{`

when
      Channel `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'astro:sun:home:set#event'`}</span>{` triggered `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`END`}</span>{` or Item  `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`TEST`}</span>{` received command `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`OFF`}</span>{`
then
      PrivacyAreas`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`sendCommand`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`OFF`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      `}<span parentName="code" {...{
            "className": "token function"
          }}>{`logInfo`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"Astro Privacy, astroScenes.rules"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"Info message :: Privacy OFF"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
end`}</code></pre></div>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/45fc2cbcbb5cdbe1465f8a6c8a326f87/159fb/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7rAAAO6wFxzYGVAAAA+ElEQVQY043OsU7DMBCAYa8MoNqOYzm+xo59cR2lkNQZ0iIlL9CnQAwsPBNiQOI1UQQSVKpKP/267XRHEBFg7b23VWXL9dCEgL+89zHGYRg8nqgRuw2ScRz3+3Gapq7riqIIm+ics9ZWfxhjqlN2URGtgVLGOc+yTAgRQgAAKaW4IBNSSZELMs9z3/fee8QaAFJKh8NjSoMxtizNmYwFBek41v1mebvrO+ccImqt23a726UYG63hfABKFs30YKMjbbtFROeWy0opSilj7HtesLpbUUrJfRNqC2h0bUHJ/N+1H3yJ+NeP/PmdP73lL598OLLbG8Yzdp0vtKY6BMgKvnQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/45fc2cbcbb5cdbe1465f8a6c8a326f87/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.avif 230w", "/en/static/45fc2cbcbb5cdbe1465f8a6c8a326f87/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.avif 460w", "/en/static/45fc2cbcbb5cdbe1465f8a6c8a326f87/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.avif 920w", "/en/static/45fc2cbcbb5cdbe1465f8a6c8a326f87/5309c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.avif 1019w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/45fc2cbcbb5cdbe1465f8a6c8a326f87/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.webp 230w", "/en/static/45fc2cbcbb5cdbe1465f8a6c8a326f87/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.webp 460w", "/en/static/45fc2cbcbb5cdbe1465f8a6c8a326f87/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.webp 920w", "/en/static/45fc2cbcbb5cdbe1465f8a6c8a326f87/6b83e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.webp 1019w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/45fc2cbcbb5cdbe1465f8a6c8a326f87/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.png 230w", "/en/static/45fc2cbcbb5cdbe1465f8a6c8a326f87/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.png 460w", "/en/static/45fc2cbcbb5cdbe1465f8a6c8a326f87/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.png 920w", "/en/static/45fc2cbcbb5cdbe1465f8a6c8a326f87/159fb/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.png 1019w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/45fc2cbcbb5cdbe1465f8a6c8a326f87/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_06.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`So that I do not have to wait for sunset or sunrise to see if the rule set is working, I added a second trigger with an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`or`}</code>{` - a simple `}<strong parentName="p">{`Switch Item`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6929963acbac2669007e6934779115c0/c56af/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "47.39130434782609%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7rAAAO6wFxzYGVAAABRElEQVQoz23Oy1LCMBQG4L6AQ0qbc9KQ5OQmhYIghVHUcaMLRhfqG/AIzLDANW/CozqUeh2/OZv8yZ8kiTHWdb1YLKqqItULzpFtee8BYL1e7/d7RHTO2R+CtQnR8TARKaWUNs559ymEwDnf7d4Ph4MQwvvvreYil1wvb6+ul9Z66zwRGWP0b02o/+SmkUyqcj4dD6IrAyHwNE3zLMu/ZBkH4IDZzzDPu43Ev27921Y+bdTzJqcy7ZyJQhpDWhsy1EX5spxtVnddlNSEWlOv12vL/ZtV/2Zl6gc7f+RSp6zDOYhGIUSGYqLx3mKGx+UJIrbl6txfDMtIKlqFnDPGlFJE9uuf2XF4/ltbvphML2d1ORj6EAEwZayQSoeBkErg6fmiaP/RDqJoy+PxeDQaxRhDCADAGNPG9ocjKQsAwP8AwKn8AVezQ0uUFPkXAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6929963acbac2669007e6934779115c0/e4706/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.avif 230w", "/en/static/6929963acbac2669007e6934779115c0/d1af7/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.avif 460w", "/en/static/6929963acbac2669007e6934779115c0/7f308/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.avif 920w", "/en/static/6929963acbac2669007e6934779115c0/3ef69/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.avif 1231w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6929963acbac2669007e6934779115c0/a0b58/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.webp 230w", "/en/static/6929963acbac2669007e6934779115c0/bc10c/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.webp 460w", "/en/static/6929963acbac2669007e6934779115c0/966d8/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.webp 920w", "/en/static/6929963acbac2669007e6934779115c0/5e57d/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.webp 1231w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6929963acbac2669007e6934779115c0/81c8e/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png 230w", "/en/static/6929963acbac2669007e6934779115c0/08a84/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png 460w", "/en/static/6929963acbac2669007e6934779115c0/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png 920w", "/en/static/6929963acbac2669007e6934779115c0/c56af/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png 1231w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6929963acbac2669007e6934779115c0/c0255/OpenHAB_v3_with_INSTAR_MQTTv5_WQHD_Cameras_05.png",
              "alt": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "title": "OpenHAB with your WQHD INSTAR MQTTv5 Broker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Toggling this switch now toggles the `}<strong parentName="p">{`PrivacyAreas`}</strong>{` switch that will turn the privacy mode on and off. This debug switch can be removed once you verified that it is working.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`DEBUGGING`}</strong>{`: If something isn't working right away and you set up OpenHab as described in this tutorial, you can check the `}<strong parentName="p">{`OpenHab Log`}</strong>{` with `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`tail -f /opt/openhab/openhab_userdata/logs/openhab.log`}</code>{`.`}</p>
    </blockquote>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      